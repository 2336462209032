import React from "react";
import '../styles/Services.css'
import Cabecera from "./Cabecerca";


function Services() {
    document.title = 'Servicios | Servicios Operativos de Ingenieria'
    return(
        <>
        <Cabecera title="Nuestros Servicios" />
        <section className="services">
        <div className="servicio">
            <div className="content">
            <h3>Cableado Estructurado</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo.</p>
                <button type="button"><a href="#">Solicite más información</a></button>
            </div>
        </div>
        <div className="servicio"  >
            
            <div className="content">
            <h3>Electricidad</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo.</p>
                <button type="button"><a href="#">Solicite más información</a></button>
            </div>
        </div>
        <div className="servicio"  >
            <div className="content">
            <h3>Sistemas contra incendios</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo.</p>
                <button type="button"><a href="#">Solicite más información</a></button>
            </div>
            </div>
        <div className="servicio"  >
            
            <div className="content">
            <h3>Sistemas de vídeo</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo.</p>
                <button type="button"><a href="#">Solicite más información</a></button>
            </div>
 </div>
        <div className="servicio"  >
            <div className="content">
            <h3>Alarmas</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo.</p>
                <button type="button"><a href="#">Solicite más información</a></button>
            </div>
            <img src="../assets/img1.jpg" alt="" />
        </div>
        <div className="servicio"  >
            <div className="content">
                <h3>Aire acondicionado</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, hic cum, harum id quas omnis voluptatem corrupti sit blanditiis reprehenderit nesciunt inventore nemo officiis dolorem voluptatum dignissimos. Maxime, ad nemo.</p>
                <button type="button"><a href="#">Solicite más información</a></button>
            </div>
            </div>
        </section></>
    )
}

export default Services