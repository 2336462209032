import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './viewports/components/Footer';
import ContactPage from './viewports/components/ContactPage';
import Productos from './viewports/components/Productos';
import Proyectos from './viewports/components/Proyectos';
import Header from './viewports/components/Header';
import Inicio from './viewports/components/Inicio';
import Services from './viewports/components/Services';
import Error404 from './viewports/components/Error404';
import Nosotros from './viewports/components/Nosotros';
import Noticias from './viewports/components/Noticias';

function App() {
  return (
    <Router> 
      <Header></Header>
      <Routes>
                    <Route exact path="/" element={<Inicio />} />
                    <Route exact path="/nosotros" Component={Nosotros} />
                    <Route exact path="/noticias" Component={Noticias} />
                    <Route exact path="/productos" element={<Productos />} />
                    <Route exact path="/servicios" Component={Services} />
                    <Route exact path="/proyectos" element={<Proyectos />} />
                    <Route exact path="/contacto" element={<ContactPage/>} />
                    <Route exact path="*" element={<Error404 />} />
                </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;


