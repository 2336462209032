import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

function BurguerButton(props) {
  return (
    <button onClick={props.handleClick} className={`nav-btn ${props.clicked ? 'nav-btn-close' : ''}`}>
      {props.clicked ? (
                <FontAwesomeIcon icon={faBars} style={{ color: '#f8d305' }} size="2x" />

      ) : (
        <FontAwesomeIcon icon={faXmark} style={{ color: '#f9d305' }} size="2x" />
      )}
    </button>
  );
}

export default BurguerButton;
