import React from "react";
import '../styles/noticias.css'
import Cabecera from "./Cabecerca";
function Noticias(){
    function crearNoticias(){
        //for (let i = 0; index < array.length; index++) {
            
            <div className="noticia">
                <div>
                    <a href=""><img src="" alt="" srcset="" /></a>
                </div>
                <div>
                    <a href="http://"><h2>Titulo</h2></a>
                    <h5>Autor</h5>
                    <h5>Fecha</h5>
                    <p>contenido</p>
                </div>
            </div>
            
       // }
    }
    return(<div>
        <section className="section-noticias">
            <Cabecera title='Noticias' />
            <div className="noticias">

            </div>
        </section>
    </div>)
}
export default Noticias