import '../styles/nosotros.css'
import Cabecera from './Cabecerca'


function Nosotros (){
    document.title = 'Nosotros | Servicios Operativos de Ingenieria'
    return(
        <div>
            <div className="nosotros-container">
            <Cabecera title='Nosotros'/>
            <div className="section-nosotros">
                <img src="assets/services-img/imagen-prueba.jpg" alt="" />
                <div className='text-nosotros'>
                <h2>¿Quiénes somos?</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus officiis voluptates incidunt! Aut eligendi consequatur qui deserunt suscipit perspiciatis ipsa sint magnam a, laboriosam ut, sunt assumenda perferendis libero aperiam?</p>
                </div>
                
            </div>
            <div className="section-nosotros">
                <div className='text-nosotros'>
                <h2>Nuestra Visión </h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus officiis voluptates incidunt! Aut eligendi consequatur qui deserunt suscipit perspiciatis ipsa sint magnam a, laboriosam ut, sunt assumenda perferendis libero aperiam?</p>
                </div>
                <img src="assets/services-img/imagen-prueba.jpg" alt="" />

            </div>
            <div className="section-nosotros">
                <img src="assets/services-img/imagen-prueba.jpg" alt="" />
                <div className='text-nosotros'>
                <h2>Nuestra Misión </h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus officiis voluptates incidunt! Aut eligendi consequatur qui deserunt suscipit perspiciatis ipsa sint magnam a, laboriosam ut, sunt assumenda perferendis libero aperiam?</p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Nosotros