import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelopeOpenText, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import BurguerButton from './BurgerButton';
import '../styles/Header.css'

const Header = () => {

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return(
    <>
      <div className="header" >
        <div className='header-sup'>
        <div className="logo">
        <NavLink exact to='/' activeClassName='active'><img src='/assets/Logo-soi.png' alt='Logo-SOI' /></NavLink> 
         
        </div>
        <div className="info">
          <div>
            <FontAwesomeIcon icon={faPhone} size='2x' />
            <span>999999999 <br/>Llamar Ahora</span>
          </div>
          <div>
            <FontAwesomeIcon icon={faEnvelopeOpenText} size='2x' />
            <span>ventas@fff.com<br/>asssc@gkbmdkf.com</span>
          </div>
        </div>
        <div className='burger-close'>
        <BurguerButton clicked={clicked} handleClick={handleClick}></BurguerButton> 
      </div>
      
        </div>

       
      <nav className={`header-menu ${clicked ? 'responsive_nav' : ''}`}>
      <li>
              <NavLink exact to='/' activeClassName='active' className={clicked ? 'responsive_nav' : ''} onClick={handleClick}>Inicio</NavLink>
            </li>
            <li>
              <NavLink exact to='/nosotros' activeClassName='active' className={clicked ? 'responsive_nav' : ''} onClick={handleClick}>Nosotros</NavLink>
            </li>
            <li>
              <NavLink exact to='/noticias' activeClassName='active'className={clicked ? 'responsive_nav' : ''} onClick={handleClick}>Noticias</NavLink>
            </li>
            <li>
              <NavLink exact to='/productos' activeClassName='active'className={clicked ? 'responsive_nav' : ''} onClick={handleClick}>Productos</NavLink>
            </li>
            <li>
              <NavLink exact to='/servicios' activeClassName='active'className={clicked ? 'responsive_nav' : ''} onClick={handleClick}>Servicios</NavLink>
            </li>
            <li>
              <NavLink exact to='/proyectos' activeClassName='active'className={clicked ? 'responsive_nav' : ''} onClick={handleClick}>Proyectos</NavLink>
            </li>
            <li>
              <NavLink exact to='/contacto' activeClassName='active'className={clicked ? 'responsive_nav' : ''} onClick={handleClick}>Contacto</NavLink>
            </li>
      </nav>
      </div>
    
      
      </>
  );
}

export default Header;


