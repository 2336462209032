import React from "react";
import Cabecera from "./Cabecerca";

function Proyectos(){
    document.title = 'Proyectos | Servicios Operativos de Ingenieria'
    return(
        <><Cabecera title='Nuestros Proyectos'/>
        <section className="productos">
                <div className="producto">
                    <h3>Proyecto 1</h3>
                    <div className="content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing...</p>
                        <a href="#">Ver más...</a>
                    </div>
                </div>
                <div className="producto">
                    <h3>Proyectos 2</h3>
                    <div className="content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing...</p>
                        <a href="#">Ver más...</a>
                    </div>
                </div>
                <div className="producto">
                    <h3>Proyectos 3</h3>
                    <div className="content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing...</p>
                        <a href="#">Ver más...</a>
                    </div>
                </div>
                
            </section></>
    )
}

export default Proyectos