import { useRef } from "react";
import Carouselp from "./Carouselp";
import Contact from './Contact'
import prueba from './assets/prueba.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {faClock, faPeopleLine, faHandshake} from '@fortawesome/free-solid-svg-icons'
import '../styles/inicio.css'
function Inicio(){
    document.title = 'Inicio | Servicios Operativos de Ingenieria';
       
    return(
        <div className="inicio-container" styled={"margin-top:80px"}>
            <div className="section-inicio">
               <Carouselp/>
            </div>
            <div className="section-inicio">
                <h2>Principales servicios</h2>
                <div className="inicio-services">
                    <div className="inicio-service">
                       <img style={{height:'150px'}} src={prueba} alt="" srcset="" />
                        <h3>Cableado Estucturado</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, exercitationem.</p>
                    </div>
                    <div className="inicio-service">
                       <img style={{height:'150px'}} src={prueba} alt="" srcset="" />
                        <h3>Aire Acondicionado</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, exercitationem.</p>
                    </div>
                    <div className="inicio-service">
                       <img style={{height:'150px'}} src={prueba} alt="" srcset="" />
                        <h3>Sistemas de Video</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, exercitationem.</p>
                    </div>
                    <div className="inicio-service">
                       <img style={{height:'150px'}} src={prueba} alt="" srcset="" />
                        <h3>Camaras de Vídeo</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, exercitationem.</p>
                    </div>
                </div>
            </div>
            <div className="section-inicio">
                <img src="" alt="" />
                <h2>¿Por qué elegirnos?</h2>
                <div className="inicio-services"> <div className="inicio-service">
                    <FontAwesomeIcon icon={faPeopleLine} size="2xl" style={{color: "#f9d305",}} />
                        <h3>Equipo</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, exercitationem.</p>
                    </div>
                    <div className="inicio-service">
                    <FontAwesomeIcon icon={faClock} size="2xl" style={{color: "#f9d305",}} />                      
                        <h3>Puntualidad</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, exercitationem.</p>
                    </div>
                    <div className="inicio-service">
                    <FontAwesomeIcon icon={faHandshake} size="2xl" style={{color: "#f9d305",}} />
                        <h3>Confianza</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos, exercitationem.</p>
                    </div>
                    </div>
            </div>
            <Contact></Contact>
        </div>
    )
}
export default Inicio