import Cabecera from "./Cabecerca";
import Contact from "./Contact"

function ContactPage(){
    document.title = 'Contacto | Servicios Operativos de Ingenieria'
    return(
        <div>
            <Cabecera title='contacto'/>
            <Contact></Contact>
        </div>
    )
}

export default ContactPage