import React from "react";
import '../styles/cabecera.css'
function Cabecera(props){
    const { backgroundImage } = props;

  const componentStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };
    return(
        <div className="cabecera" style={componentStyle}>
            <h2>{props.title}</h2>
        </div>
    )
}

export default Cabecera