import React from 'react'
import { styled } from 'styled-components'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import '../styles/Footer.css'
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };  
  
  return (
            <><footer className='footer'>
              <div className='footter-content' >
              <div className='footer-box' id='text'>
                <img src='/assets/Logo-soi.png' alt='Logo-SOI' />
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta fugit, natus neque aliquam dignissimos nobis minima accusamus voluptatem similique, ipsa sint! Voluptatibus  </p>
            </div>
            <div className='footer-box'>
                <h3>Servicios</h3>
                <ul>
                    <li><a href='#'>Cableado estructurado</a></li>
                    <li><a href='#'>Electricidad</a></li>
                    <li><a href='#'>Sistemas contra incendios</a></li>
                    <li><a href='#'>Sistemas de Video</a></li>
                    <li><a href='#'>Aire Acondicionado</a></li>
                    <li><a href='#'>Alarma</a></li>
                </ul>
            </div>
            <div className='footer-box'>
                <h3>Secciones</h3>
                <ul>
            <li>
              <NavLink exact to='/' activeClassName='active'onClick={scrollToTop}>Inicio</NavLink>
            </li>
            <li>
              <NavLink exact to='/nosotros' activeClassName='active' onClick={scrollToTop}>Nosotros</NavLink>
            </li>
            <li>
              <NavLink exact to='/productos' activeClassName='active' onClick={scrollToTop}>Productos</NavLink>
            </li>
            <li>
              <NavLink exact to='/servicios' activeClassName='active' onClick={scrollToTop}>Servicios</NavLink>
            </li>
            <li>
              <NavLink exact to='/proyectos' activeClassName='active' onClick={scrollToTop}>Proyectos</NavLink>
            </li>
            <li>
              <NavLink exact to='/contacto' activeClassName='active' onClick={scrollToTop}>Contacto</NavLink>
            </li>
          </ul>
            </div>
            <div className='footer-box'>
                <h3>Contacto</h3>
                <p></p>
            </div>
              </div>
              <div className='autor'>
                Copyright &copy;2023 Desarrollado por: Agustin Villalta. All rights reserved
            </div>
        </footer></>
        
    )
}

export default Footer

