import React from "react";
import '../styles/Producto.css'
import Cabecera from "./Cabecerca";
function Productos(){
    document.title = 'Productos | Servicios Operativos de Ingenieria'
    return(
        <>
        <Cabecera title='productos'/>
            <section className="productos">
                <div className="producto">
                    <h3>Equipos</h3>
                    <div className="content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing...</p>
                        <a href="#">Ver más...</a>
                    </div>
                </div>
                <div className="producto">
                    <h3>Cables</h3>
                    <div className="content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing...</p>
                        <a href="#">Ver más...</a>
                    </div>
                </div>
                <div className="producto">
                    <h3>Herramientas</h3>
                    <div className="content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing...</p>
                        <a href="#">Ver más...</a>
                    </div>
                </div>
                
            </section></>
    )
}

export default Productos